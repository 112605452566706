
function endEventCreator (drawType, isSuccessful, feature) {
    return {
        drawType,
        type: 'onStatusChange',
        status: 'FINISHED',
        isSuccessful,
        feature
    }
}

export {
    endEventCreator
}
