import { TO_RAD, TO_DEG } from '../panogl/Utils'
import { FRAGMENT_STR } from './fragment'
import { VERTEX_STR } from './vertex'
import { DLineTool } from './DLineTool'
import { DPointTool } from './DPointTool'
import { DPolygonTool } from './DPolygonTool'

const TYPES = {
    LINE: 'LINE',
    POINT: 'POINT',
    POLYGON: 'POLYGON'
}

var RGBToHex = function (r, g, b) {
    var bin = r << 16 | b << 8 | g
    return bin
}

class DepthPlugin extends AnkaPanAPI.PanoPlugin {
    constructor (options) {
        super()
        const { url } = options
        if (url === undefined) {
            throw new Error('"url" param cannot be undefined')
        }
        this._url = url[url.length - 1] === '/' ? url.substr(0, url.length - 1) : url
        this.cloneable = false
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this._onToolStatusChange = this._onToolStatusChange.bind(this)
    }

    start (type) {
        if (this.currentTool) {
            this.stop()
            return
        }

        let pano = this.baseObject
        let scalablePlugin = pano.getScalable()
        if (type === TYPES.POINT) {
            this.currentTool = new DPointTool(this, scalablePlugin.getMainSketchLayer())
        } else if (type === TYPES.LINE) {
            this.currentTool = new DLineTool(this, scalablePlugin.getMainSketchLayer())
        } else if (type === TYPES.POLYGON) {
            this.currentTool = new DPolygonTool(this, scalablePlugin.getMainSketchLayer())
        } else {

        }

        if (this.currentTool) {
            this.currentTool.addEvent('onStatusChange', this, this._onToolStatusChange)
            let pano = this.baseObject
            pano.disableGroundNav(true)
            var gm = pano.getGroundMaster()
            gm.showGroundSymbol(false)
            pano.addEvent(AnkaPanAPI.PanoGLV2.RENDER_AFTER_GROUND, this, this._onRender)
            pano.addEvent(AnkaPanAPI.PanoGLV2.MOUSE_MOVE2D, this, this._onMouseMove)
            pano.addEvent(AnkaPanAPI.PanoGLV2.SAFE_CLICK2D, this, this._onClick)
            pano.addEvent(AnkaPanAPI.PanoGLV2.LOCATION_CHANGE, this, this._onLocationChange)
            document.addEventListener('keydown', this.onKeyDown)
            document.addEventListener('keyup', this.onKeyUp)
            this._onLocationChange()
            this.throwEvent({ type: 'onStatusChange', feature: this.currentTool.gdh, status: 'STARTED', drawType: this.currentTool.type })
        }
    }

    _onToolStatusChange (e) {
        if (e.status === 'FINISHED') {
            this.stop()
        } else {
        }
        this.throwEvent(e)
    }

    _onClick (e) {
        let clickButton = e.nativeEvent.button

        if (this.currentTool) {
            if (clickButton === 2) {
                this.currentTool.endDraw()
            } else if (clickButton === 1) {
                this.currentTool.removeLastPoint()
            }
        }

        if (!this._mouselastPoint) return

        if (this.currentTool) {
            if (clickButton === 0) {
                this.currentTool.addPoint(this._lastCoord)
            }
        }
    }

    stop () {
        if (this.currentTool) {
            this.currentTool.removeEvent('onStatusChange', this._onToolStatusChange)
        }
        this.currentTool = null
        AnkaPanAPI.CursorManager.setCursor('auto', -1, true)
        let pano = this.baseObject
        var gm = pano.getGroundMaster()
        pano.disableGroundNav(false)
        // gm.enableNavigation()
        gm.showGroundSymbol(true)

        pano.removeEvent(AnkaPanAPI.PanoGLV2.RENDER_AFTER_GROUND, this._onRender)
        pano.removeEvent(AnkaPanAPI.PanoGLV2.MOUSE_MOVE2D, this._onMouseMove)
        pano.removeEvent(AnkaPanAPI.PanoGLV2.SAFE_CLICK2D, this._onClick)
        pano.removeEvent(AnkaPanAPI.PanoGLV2.LOCATION_CHANGE, this._onLocationChange)
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keyup', this.onKeyUp)
    }

    onKeyDown (e) {
        if (e.keyCode === 17) {
            let shaderMaterial = this._getMaterial()
            shaderMaterial.uniforms.showDepth.value = true
        } else if (e.keyCode === 27) {
            if (this.currentTool) {
                this.currentTool.badEnd()
            }
        }
    }

    onKeyUp (e) {
        if (e.keyCode === 17) {
            let shaderMaterial = this._getMaterial()
            shaderMaterial.uniforms.showDepth.value = false
        }
    }

    _createSphere () {
        const sceen = this._cursorScene
        let shaderMaterial = this._getMaterial()
        var geometry = new THREE.SphereBufferGeometry(1000, 500, 500)
        let sphere = new THREE.Mesh(geometry, shaderMaterial)
        sphere.scale.set(-1, 1, 1)
        let cover = new THREE.Object3D()
        cover.add(sphere)
        sceen.add(cover)
        this._sphere = sphere
        this.cover = cover
    }

    _getVertexStr () {
        return VERTEX_STR
    }

    loadTexture (path) {
        if (!this._textureManager) {
            this._textureManager = new THREE.TextureLoader()
            this._textureManager.setCrossOrigin('anonymous')
        }
        let textureMap = this._textureManager.load(path)
        textureMap.magFilter = THREE.NearestFilter
        textureMap.minFilter = THREE.LinearFilter
        return textureMap
    }

    _getDepthTexture () {
        return this._depthTexture
    }

    _getFragmentStr () {
        return FRAGMENT_STR
    }

    _onMouseMove (e) {
        this._mouseX = e.nativeEvent.offsetX
        this._mouseY = e.nativeEvent.offsetY
        this._ray = e.ray.direction.clone()
        const pano = this.baseObject
        const gm = pano.getGroundMaster()
        gm.showGroundSymbol(false)
    }

    _getMaterial () {
        if (!this._shaderMaterial) {
            let pano = this.baseObject
            let camera = pano.getMainCamera()
            this._shaderMaterial = new THREE.ShaderMaterial({
                transparent: true,
                fragmentShader: this._getFragmentStr(),
                vertexShader: this._getVertexStr(),
                uniforms: {
                    mouseScreenPosition: { value: new THREE.Vector3(0, 0) },
                    t_depth: { value: this._getDepthTexture() },
                    cameraNear: { value: camera.near },
                    cameraFar: { value: camera.far },
                    rangeAlpha: { value: 1 },
                    isForDistance: { value: false },
                    showDepth: { value: false },
                    u_heading: { value: 0 }

                }
            })
        }
        return this._shaderMaterial
    }

    _panoSetupComplete () {
        this._createSphere()
    }

    _onRender () {
        this._mouselastPoint = null
        let pano = this.baseObject
        let camera = pano.getMainCamera()
        let renderer = pano.getRenderer()
        let shaderMaterial = this._getMaterial()

        let ctx = pano.getRenderer().context

        if (typeof this._mouseX === 'number') {
            let reverseMouseY = pano.getRendererDom().clientHeight - this._mouseY
            shaderMaterial.uniforms.isForDistance.value = true
            shaderMaterial.uniforms.mouseScreenPosition.value.x = this._mouseX
            shaderMaterial.uniforms.mouseScreenPosition.value.y = reverseMouseY

            renderer.clearDepth()
            renderer.render(this._cursorScene, camera)

            let width = 100
            let height = 100
            let pixels = new Uint8Array(width * height * 4)
            ctx.readPixels(this._mouseX, reverseMouseY, width, height, ctx.RGBA, ctx.UNSIGNED_BYTE, pixels)

            let [r, g, b] = pixels
            let distInMeter = RGBToHex(r, g, b) / 1000

            if (distInMeter > 0.1) {
                let direction = this._ray
                let yawInRadian = -Math.atan2(direction.x, direction.z) + (Math.PI * 0.5)
                let pitchInRad = Math.asin(direction.y)
                let opt = Math.sin(pitchInRad) * distInMeter
                let adj = Math.cos(pitchInRad) * distInMeter

                let currentPoint = pano.getCurrentPoint()
                let lon = currentPoint.lon
                let lat = currentPoint.lat
                let altitude = currentPoint.altitude + opt

                let clickLocation = AnkaPanAPI.Utils.destinationPoint(lat, lon, adj, (yawInRadian * TO_DEG) + 90)

                this._lastCoord = Object.assign({ alt: altitude }, clickLocation)

                let neighbour = Math.cos(pitchInRad) * distInMeter
                let cartesianY = Math.sin(pitchInRad) * distInMeter
                let cartesianX = Math.cos(yawInRadian) * neighbour
                let cartesianZ = Math.sin(yawInRadian) * neighbour

                this._mouselastPoint = {
                    cartesianX,
                    cartesianY,
                    cartesianZ
                }
                AnkaPanAPI.CursorManager.setCursor('crosshair', 666)
            } else {
                this._lastCoord = null
                this._lastCoord = null
                AnkaPanAPI.CursorManager.setCursor('not-allowed', 666)
            }
        }

        if (this._mouselastPoint) {
            shaderMaterial.uniforms.mouseScreenPosition.value.x = this._mouselastPoint.cartesianX
            shaderMaterial.uniforms.mouseScreenPosition.value.y = this._mouselastPoint.cartesianY
            shaderMaterial.uniforms.mouseScreenPosition.value.z = this._mouselastPoint.cartesianZ
            shaderMaterial.uniforms.isForDistance.value = false
            renderer.clearDepth()
            renderer.render(this._cursorScene, camera)
        }

        // let vector = this.vector
        // let dpr = this.dpr
        // vector.x = (window.innerWidth * dpr / 2) - (textureSize / 2);
        // vector.y = (window.innerHeight * dpr / 2) - (textureSize / 2);
        // renderer.copyFramebufferToTexture(vector, texture);
    }

    _onLocationChange () {
        let pano = this.baseObject
        let currentPoint = pano.getCurrentPoint()
        let { parent, dirname, img, heading } = currentPoint
        img = img.replace('.jpeg', '.png')
        const pathParts = [this._url, 'depth', parent, dirname, img]
        let path = pathParts.filter(i => (i + '').trim()).join('/')
        let textureMap = this.loadTexture(path)
        let shaderMaterial = this._getMaterial()
        let config = pano.dataParser.getCurrentConfig()
        window._textureMap = textureMap
        shaderMaterial.uniforms.t_depth.value = textureMap
        shaderMaterial.uniforms.u_heading.value = heading + config.heading
        shaderMaterial.needsUpdate = true
    }

    onOrientationChanged (e) {
        let sphere = this._sphere
        let scene = this._cursorScene

        let { heading, roll, pitch } = e
        // pitch = 0.3 * TO_RAD
        // roll = -0.2 * TO_RAD

        pitch = 0
        roll = 0

        var newHeading = -heading - (90 * TO_RAD)
        var rotHeading = -90 * TO_RAD
        this.cover.rotation.set(pitch, -rotHeading, -roll, 'YXZ')
        sphere.rotation.set(0, rotHeading, 0, 'YXZ')
        scene.rotation.set(0, newHeading, 0)

        this.isDirty = true
    }

    prepare () {
        let pano = this.baseObject
        const _t = this
        pano.getDepthPlugin = function () {
            return _t
        }
        this._cursorScene = new THREE.Scene()
        pano.addEvent(AnkaPanAPI.PanoGLV2.SETUP_COMLETE, this, this._panoSetupComplete)
        pano.addEvent(AnkaPanAPI.PanoGLV2.ORIENTATION_CHANGED, this, this.onOrientationChanged)
    }
}
DepthPlugin.TYPES = TYPES
DepthPlugin.CLICK = 'onClick'
DepthPlugin.STATUS_CHANGE = 'onStatusChange'

export { DepthPlugin }
