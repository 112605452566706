import { endEventCreator } from './EventCreator'
import { DTool } from './DTool'

class DPointTool extends DTool {
    constructor (depthPlugin, layer) {
        super(depthPlugin, 'Point')
    }

    onPointAdd (point) {
        super.onPointAdd(point)
        const gdh = this.gdh
        const pointNumber = gdh.getPointLength()
        if (pointNumber > 0) {
            setTimeout(() => this.endDraw())
        }
    }

    endDraw () {
        const gdh = this.gdh
        const pointNumber = gdh.getPointLength()
        const GeomDataHolder = AnkaScalable.GeomDataHolder
        let isSuccessful = false
        if (pointNumber < 1) {
            gdh.setStatus(GeomDataHolder.STATUS.BAD_COMPLETED)
            AnkaScalable.GeometryObserver.removeGeometry(this.gdh)
        } else {
            isSuccessful = true
            gdh.setStatus(GeomDataHolder.STATUS.COMPLETED)
        }
        this.throwEvent(endEventCreator(this.type, isSuccessful, gdh))
    }
}

export { DPointTool }
